import React from "react";
import Helmet from "react-helmet";
import { Jumbotron } from "../components/Jumbotron";
import { Row, Col, Modal, Button  } from "react-bootstrap";
import SEO from "../components/SEO";
import "../assets/css/App.css";
import "../assets/css/index.css";

import Layout from "../components/Layout";
import { Link } from "gatsby";
import styled from "styled-components";
import { RE } from "../components/Reviews";
import ContactForm from "../components/ContactForm";

const StyledButton = styled(Button)`
  & {
    margin: 0;
  }
`;

export default () => (
  <Layout>
    <SEO
      title="Swimming Pool Boiler Installation"
      description="Get the right boiler installed to run your swimming pool efficiently and cost-effectively with a new boiler from BBIS Commercial Heating"
      keywords="london swimming pool boiler, hertfordshire swimming pool boiler, potters bar swimming pool boiler, central london swimming pool boiler repair, swimming pool boiler installation, swimming pool boiler repair"
    />
    <Jumbotron imgClassName="img3"></Jumbotron>
    <Row>
      <Col>
        <div class="white-segment text-left">
          <h1 class="heading-color">
          Swimming Pool Boiler Installation
          </h1>
          <h2 className="heading-color">We’ll install, service and repair your swimming pool boiler</h2>
          <p>Our English climate just isn’t warm enough to be able to use an indoor or outdoor swimming pool without a heater. So whether you’ve just had a pool installed or whether your current swimming pool needs a new boiler, BBIS Commercial Heating can help.</p>
          <p>We’ll install a gas or oil-fired boiler or an <Link to="/renewable-energy-air-source-heat-pumps/">air source heat pump</Link> to ensure your pool is kept at a comfortable temperature all year round. We’ll handle all installation, maintenance and repairs at a competitive price.</p>
          <h3 className="heading-color">Qualified swimming pool boiler installers</h3>
          <p>Installing a swimming pool boiler is a complex procedure, and must only be carried out by a qualified engineer. Our engineers have the additional qualifications required to install, repair and service domestic and commercial swimming pool boilers.</p>
          <p>The nature of swimming pools means that there is the possibility of chemical damage and corrosion to the heater while it’s doing its job. Our team will take these considerations into account and put the relevant measures into place to prevent them. </p>
          <p>Our engineer will be happy to go through your options with you and explain in further detail when they’re with you on-site.</p>
          <h4 className="heading-color">How BBIS Commercial Heating can help</h4>
          <ul>
            <li>Install a gas or oil-fired boiler or heat pump to heat your swimming pool</li>
            <li>Service your swimming pool boiler to keep your manufacturer’s warranty valid</li>
            <li>Offer advice and guidance on the best solution for your swimming pool</li>
          </ul>
          <h4 className="heading-color">Recently completed projects</h4>
          <p>We’ve helped customers across London, Hertfordshire and the surrounding areas with new boilers, plant rooms, underfloor heating and more. Take a look at our recent projects to see what we’ve been up to.</p>
          <StyledButton
            href="/projects/"
            variant="primary"
            size="lg"
          >
            View more recent projects
          </StyledButton>
          <p>BBIS Commercial Heating engineers are fully licenced and qualified to work on all swimming pool boilers. Call us on 0800 2289800 or fill in our form below to find out more.</p>
        </div>
      </Col>
      </Row>
      <Row>
        <Col>
        <div className="grey-segment">
          <h3 className="color-heading">FAQs</h3>
          <h4 className="heading-color">Can a domestic boiler heat a swimming pool?</h4>
          <p>Yes, a domestic boiler can heat a swimming pool, but it will need to have a <strong>heat exchanger</strong> installed alongside it. This ensures that the pool water and the boiler water never come into contact with each other, <strong>indirectly transferring heat</strong> instead.</p>
          <p>The benefit of using a domestic boiler and heat exchanger this way is that a domestic boiler is often <strong>more efficient,</strong> so may cost less to run. It also takes up a lot <strong>less space,</strong> and you may experience fewer breakdowns as the pool water never enters the boiler.</p>
          <p>Domestic boilers aren’t suitable to heat every type of swimming pool. Our engineers will always recommend the best solution for your situation.</p>
          <h4 className="heading-color">What is the most efficient way to heat a swimming pool?</h4>
          <p>In most cases, the most efficient way to heat a swimming pool is with an <strong>air source heat pump.</strong> Heat pumps draw heat from the air and transfer it to water, which then works with your pool’s filter system to heat up your pool.</p>
          <p>While this is the most efficient way to heat your pool, you may also need to invest in things like solar pool covers and enclosures to maximise the benefits, which can drive up the cost.</p>
          <h4 className="heading-color">How long does a pool boiler last?</h4>
          <p>How long a pool boiler lasts depends on how well it’s maintained, but you should expect to get up to <strong>12 years</strong> from a well-looked-after <strong>gas or oil swimming pool boiler.</strong> If you choose an <strong>air source heat pump</strong> to heat your pool, it could last up to <strong>10 years.</strong></p>
        </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="white-segment">
            <RE></RE>
            <h3 className='heading-color'>Contact BBIS Heating</h3>
            <ContactForm></ContactForm>
          </div>
        </Col>
      </Row>

  </Layout>
);
